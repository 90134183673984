/* 
██╗██╗░░░██╗  ██████╗░██╗░██████╗░██╗████████╗░█████╗░██╗░░░░░
██║██║░░░██║  ██╔══██╗██║██╔════╝░██║╚══██╔══╝██╔══██╗██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░██╗░██║░░░██║░░░███████║██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░╚██╗██║░░░██║░░░██╔══██║██║░░░░░
██║╚██████╔╝  ██████╔╝██║╚██████╔╝██║░░░██║░░░██║░░██║███████╗
╚═╝░╚═════╝░  ╚═════╝░╚═╝░╚═════╝░╚═╝░░░╚═╝░░░╚═╝░░╚═╝╚══════╝
╔════════════════════════════════════════════════════════════╗
║              EQUIPO DE DESARROLLO DE SOFTWARE              ║
║                   MODULO DESARROLLADO POR                  ║
╟┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄╢
║ Juan Esteban Atehortua Sanchez                             ║
║ juan.atehortua@iudigital.edu.co                            ║
╚════════════════════════════════════════════════════════════╝
*/

import fetchWrapper from "../../../fetchWrapper";

export const RoleListService = async (pageIndex) => {
  try {
    const token = localStorage.getItem("token"); // O desde donde guardes tu token
    const user = localStorage.getItem("user_id"); // O desde donde guardes tu user
    const response = await fetch(`${process.env.REACT_APP_API_URL}/roles?page=${pageIndex}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        user: user,
      },
      body: JSON.stringify(),
    });
    const { payload } = await response.json();
    return { payload };
  } catch (error) {}
};

export const RolePermisionsListService = async () => {
  try {
    const token = localStorage.getItem("token"); // O desde donde guardes tu token
    const user = localStorage.getItem("user_id"); // O desde donde guardes tu user
    const response = await fetch(`${process.env.REACT_APP_API_URL}/permissions`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        user: user,
      },
      body: JSON.stringify(),
    });
    const { payload } = await response.json();
    return { payload };
  } catch (error) {}
};

export const RoleByIDService = async (id) => {
  try {
    const response = await fetchWrapper(`${process.env.REACT_APP_API_URL}/roles/rol/${id}`);
    const { payload } = await response.json();
    return { payload };
  } catch (error) {}
};

export const RoleCreateService = async (payload) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/roles/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const result = await response.json();
    const { success, code } = result;
    if (success === true) {
      const {
        payload: { data },
      } = result;
      return { data };
    } else if (success === false) {
      const message = "Error del servidor al realizar la solicitud";
      const status = false;
      return { status, message };
      //throw new Error(`Error del servidor al realizar la solicitud`);
    }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};

export const RoleUpdateService = async (id, payload) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/roles/edit/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const result = await response.json();
    const { success, code } = result;
    if (success === true) {
      const {
        payload: { data },
      } = result;
      return { data };
    } else if (success === false) {
      const message = "Error del servidor al realizar la solicitud";
      const status = false;
      return { status, message };
      //throw new Error(`Error del servidor al realizar la solicitud`);
    }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};

export const RoleDeleteService = async (id, payload) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/roles/delete/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const result = await response.json();
    const { success, code } = result;
    if (success === true) {
      const {
        payload: { data },
      } = result;
      return { data };
    } else if (success === false) {
      const message = "Error del servidor al realizar la solicitud";
      const status = false;
      return { status, message };
      //throw new Error(`Error del servidor al realizar la solicitud`);
    }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};

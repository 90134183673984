/* 
██╗██╗░░░██╗  ██████╗░██╗░██████╗░██╗████████╗░█████╗░██╗░░░░░
██║██║░░░██║  ██╔══██╗██║██╔════╝░██║╚══██╔══╝██╔══██╗██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░██╗░██║░░░██║░░░███████║██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░╚██╗██║░░░██║░░░██╔══██║██║░░░░░
██║╚██████╔╝  ██████╔╝██║╚██████╔╝██║░░░██║░░░██║░░██║███████╗
╚═╝░╚═════╝░  ╚═════╝░╚═╝░╚═════╝░╚═╝░░░╚═╝░░░╚═╝░░╚═╝╚══════╝
╔════════════════════════════════════════════════════════════╗
║              EQUIPO DE DESARROLLO DE SOFTWARE              ║
║                   MODULO DESARROLLADO POR                  ║
╟┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄╢
║ Victor Manuel Calle Londoño                                ║
║ victor.calle@est.iudigital.edu.co                          ║
╚════════════════════════════════════════════════════════════╝
*/
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import MDButton from "components/MDButton";

export function CarnetEditedStatus({
  openEditedStatus,
  handleCloseEditedStatus,
  infoStatus,
  handleSubmitDelete,
}) {
  return (
    <>
      <Dialog
        open={openEditedStatus}
        onClose={handleCloseEditedStatus}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ textAlign: "center" }}
      >
        <DialogTitle id="alert-dialog-title">{"¡Importante!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Está seguro de cambiar el estado del carnet a: {infoStatus.status} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleCloseEditedStatus}>Cancelar</MDButton>
          <MDButton color="warning" onClick={handleSubmitDelete}>
            Aplicar
          </MDButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Users from "layouts/user";
import UsersCarnet from "layouts/user_carnet";
import UsersCard from "layouts/user_card";
import Sources from "layouts/sources_data";
import Role from "layouts/role";
import AdminMan from "layouts/admin_manager";
import Bienvenida from "layouts/welcome";
import DataTable from "layouts/user/modules/showCarnet/modal";
import ViewUserPerCard from "layouts/user_carnet/modules/ViewUserPerCard";
import CardType from "layouts/card_type";

// @mui icons
import Icon from "@mui/material/Icon";
import Verify from "layouts/verify_carnets";
import VerifyCarnetID from "layouts/verify_carnets_id";
import SourcesFile from "layouts/sources_data_file";
import CarnetsPerFile from "layouts/carnets_per_file";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import HowToRegIcon from "@mui/icons-material/HowToReg";

const routes = [
  {
    route: "/Bienvenida",
    component: <Bienvenida />,
    private: true,
  },
  {
    key: "dashboard",
    route: "/dashboard",
    component: <Dashboard />,
    private: true,
  },
  {
    key: "table",
    route: "/tables",
    component: <Tables />,
    private: true,
  },
  {
    type: "collapse",
    name: "Fuentes",
    key: "Fuentes",
    icon: <FileUploadIcon fontSize="small">group</FileUploadIcon>,
    route: "/Fuentes",
    component: <Sources />,
    private: true,
  },
  {
    type: "collapse",
    name: "Verificar carnets",
    key: "verify",
    icon: <HowToRegIcon fontSize="small">group</HowToRegIcon>,
    route: "/verify",
    component: <Verify />,
    private: true,
  },
  {
    type: "collapse",
    name: "Usuarios",
    key: "Usuarios",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/Usuarios",
    component: <UsersCard />,
    private: true,
  },
  {
    type: "collapse",
    name: "Carnets",
    key: "Carnets",
    icon: <VerifiedUserIcon fontSize="small">group</VerifiedUserIcon>,
    route: "/Carnets",
    component: <UsersCarnet />,
    private: true,
  },
  {
    /*type: "collapse",
    name: "Sincronizacion",
    key: "admin_user",
    icon: <Icon fontSize="small">group</Icon>,*/
    route: "/admin_sync",
    component: <AdminMan />,
    private: true,
  },
  {
    key: "sources_file",
    route: "/Fuentes/:id",
    component: <SourcesFile />,
    private: true,
  },
  {
    key: "carnets_per_file",
    route: "/Fuentes/:id/:id",
    component: <CarnetsPerFile />,
    private: true,
  },
  {
    type: "collapse",
    name: "Tipos de carnet",
    key: "card_type",
    icon: <ViewCarouselIcon fontSize="small">group</ViewCarouselIcon>,
    route: "/card_type",
    component: <CardType />,
    private: true,
  },
  {
    route: "/verify/:id/:qr",
    component: <VerifyCarnetID />,
    private: true,
  },
  {
    type: "collapse",
    name: "Administradores",
    key: "Administradores",
    icon: <ManageAccountsIcon fontSize="small">group</ManageAccountsIcon>,
    route: "/Administradores",
    component: <Users />,
    private: true,
  },
  {
    type: "collapse",
    name: "Roles",
    key: "Roles",
    icon: <SwitchAccountIcon fontSize="small">group</SwitchAccountIcon>,
    route: "/Roles",
    component: <Role />,
    private: true,
  },
  {
    key: "billing",
    route: "/billing",
    component: <Billing />,
    private: true,
  },
  {
    key: "rtl",
    route: "/rtl",
    component: <RTL />,
    private: true,
  },
  {
    key: "notification",
    route: "/notifications",
    component: <Notifications />,
    private: true,
  },
  {
    key: "profile",
    route: "/profile",
    component: <Profile />,
    private: true,
  },
  {
    key: "sign-in",
    route: "/auth/sign-in",
    component: <SignIn />,
    private: false,
  },
  {
    key: "sign-up",
    route: "/auth/sign-up",
    component: <SignUp />,
    private: true,
  },
  {
    /*
    type: "collapse",
    name: "carnets",
    key: "carnet",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/carnets",
    component: <UserListCarnet />,*/
  },

  {
    /*
    type: "collapse",
    name: "Modal",
    key: "mcarnet",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/index",
    component: <ModalCarnet />,*/
  },
  {
    /*type: "collapse",
    name: "Dcarnet",
    key: "Dcarnet",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/CarnetDownload",
    component: <CarnetDownload />,*/
  },
];

export default routes;

/* 
██╗██╗░░░██╗  ██████╗░██╗░██████╗░██╗████████╗░█████╗░██╗░░░░░
██║██║░░░██║  ██╔══██╗██║██╔════╝░██║╚══██╔══╝██╔══██╗██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░██╗░██║░░░██║░░░███████║██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░╚██╗██║░░░██║░░░██╔══██║██║░░░░░
██║╚██████╔╝  ██████╔╝██║╚██████╔╝██║░░░██║░░░██║░░██║███████╗
╚═╝░╚═════╝░  ╚═════╝░╚═╝░╚═════╝░╚═╝░░░╚═╝░░░╚═╝░░╚═╝╚══════╝
╔════════════════════════════════════════════════════════════╗
║              EQUIPO DE DESARROLLO DE SOFTWARE              ║
║                   MODULO DESARROLLADO POR                  ║
╟┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄╢
║ Cristia David Lemus Calderón                               ║
║ cristian.lemus@iudigital.edu.co                            ║
╚════════════════════════════════════════════════════════════╝
*/

import { useState } from "react";
import VerifyByDocument from "../modules/VerifyByDocument";
import { getAllCardPerDocumentUser } from "../services/VerifyCarnetService";

export default function VerifyCarnetController() {
  const [document, setNewData] = useState({});
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  //ejecuta la funcion requestUpdateValidity con la información necesaria y cierra el modal de actualizar vigencia
  const handleSubmitRequest = async (e) => {
    setIsLoading(true);
    try {
      e.preventDefault();
      const result = await getAllCardPerDocumentUser(document.name);
      await setData(result.data);
      setIsLoading(false);
    } catch (error) {}
  };

  const handleFieldChange = (fieldName, value) => {
    setNewData({
      ...document,
      [fieldName]: value,
    });
  };

  return (
    <>
      <VerifyByDocument
        handleFieldChange={handleFieldChange}
        handleSubmit={handleSubmitRequest}
        data={data}
        isLoading={isLoading}
      ></VerifyByDocument>
    </>
  );
}

/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import { Container, Typography, Button } from "@mui/material";
import { carnet } from "react-router-dom";

// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Data
import profilesListData from "layouts/profile/data/profilesListData";

// Images
import imagen from "layouts/welcome/images/imagenn.jpg";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import { useEffect, useState } from "react";
import fetchWrapper from "fetchWrapper";

function Bienvenida() {
  const { sales, tasks } = reportsLineChartData;
  const [statistics, setStatistics] = useState({
    query1: 0,
    query2: 0,
    query3: 0,
    query4: 0,
    query5: [],
    query6: [],
    query7: [],
    query8: 0,
  });
  const [lastUpdated, setLastUpdated] = useState("");

  const getDefaultIfEmpty = (value) => {
    if (value === null || value === undefined || (Array.isArray(value) && value.length === 0)) {
      return 0;
    }
    return value;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchWrapper(`${process.env.REACT_APP_API_URL}/charts/`);
        const data = await response.json();

        console.log("Data:", data);

        // Actualizar el estado del gráfico
        setStatistics({
          query1: getDefaultIfEmpty(data?.query1),
          query2: getDefaultIfEmpty(data?.query2?.length),
          query3: getDefaultIfEmpty(data?.query3?.length),
          query4: getDefaultIfEmpty(data?.query4),
          query5: getDefaultIfEmpty(data?.query5),
          query6: getDefaultIfEmpty(data?.query6),
          query7: getDefaultIfEmpty(data?.query7),
          query8: getDefaultIfEmpty(data?.query8),
        });

        // Establecer la hora de la última actualización
        const now = new Date();
        const formattedTime = now.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
        setLastUpdated(formattedTime);
      } catch (error) {
        console.error("Error al obtener datos:", error);
      }
    };

    // Llamar a la función para obtener datos al montar el componente
    fetchData();
  }, []); // El array vacío indica que este efecto se ejecuta solo una vez al montar el componente

  //crear una funcion que cuente y muestre hace cuanto tiempo se abrió está página
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {statistics &&
        Object.keys(statistics).length > 0 && ( // Verificar si statistics existe y tiene datos
          <>
            <MDBox py={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={3}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="dark"
                      icon="weekend"
                      title="Total de carnets"
                      count={statistics.query1}
                      date="Recién actualizado"
                      percentage={{
                        color: "success",
                        label: `Actualizado a las ${lastUpdated}`,
                      }}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      icon="leaderboard"
                      title="Total carnets estudiantes"
                      count={statistics.query2}
                      date="Recién actualizado"
                      percentage={{
                        color: "success",
                        label: `Actualizado a las ${lastUpdated}`,
                      }}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="success"
                      icon="store"
                      title="Total carnets visitantes"
                      date="Recién actualizado"
                      count={statistics.query3}
                      percentage={{
                        color: "success",
                        label: `Actualizado a las ${lastUpdated}`,
                      }}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="primary"
                      icon="person_add"
                      title="Cantidad total de tipos de fuentes"
                      count={statistics.query4}
                      percentage={{
                        color: "success",
                        amount: "",
                        label: `Actualizado a las ${lastUpdated}`,
                      }}
                    />
                  </MDBox>
                </Grid>
              </Grid>
              <MDBox mt={4.5}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={3}>
                      <ReportsBarChart
                        color="info"
                        title={<span style={{ textTransform: "none" }}>Carnets creados</span>}
                        /* description="Últimos 6 meses" */
                        date={`Actualizado a las ${lastUpdated}`}
                        chart={statistics.query5}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={3}>
                      <ReportsLineChart
                        color="success"
                        title={<span style={{ textTransform: "none" }}>Carnets por fuente</span>}
                        date={`Actualizado a las ${lastUpdated}`}
                        chart={statistics.query6}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={3}>
                      <ReportsLineChart
                        color="dark"
                        title={<span style={{ textTransform: "none" }}>Carnets usados</span>}
                        /* description="Last Campaign Performance" */
                        date={`Actualizado a las ${lastUpdated}`}
                        chart={statistics.query7}
                      />
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              {/* <MDBox>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={8}>
                    <Projects />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <OrdersOverview />
                  </Grid>
                </Grid>
              </MDBox> */}
            </MDBox>

            <Footer />
          </>
        )}
    </DashboardLayout>
  );
}
export default Bienvenida;

import { Box, Card, Divider, FormGroup, Grid, Icon, RadioGroup, Typography } from "@mui/material";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import DataTable from "examples/Tables/DataTable";
import FindInPageOutlinedIcon from "@mui/icons-material/FindInPageOutlined";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { green } from "@mui/material/colors";
import Switch from "@mui/material/Switch";

export default function VerifyById({ data, onSwitchActivate }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "450px",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    maxHeight: "90vh",
  };
  const content = {
    margin: "0",
    padding: "0",
    display: "grid",
    align: "center",
    width: "100%",
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    gap: "15px",
  };

  // Estado local para controlar el valor del Switch
  const [isChecked, setIsChecked] = useState(data?.joined === "1");

  // Actualizar el estado local cuando data?.joined cambia
  useEffect(() => {
    setIsChecked(data?.joined === "1");
  }, [data?.joined]);

  const isActive = data?.status === "Activo";
  const shouldShowSwitch =
    data?.card_type?.name === "Invitado" || data?.card_type?.name === "Visitante";
  const isDisabled = !isActive || isChecked;

  // Manejar el cambio de estado del Switch
  const handleSwitchChange = () => {
    // Permitir cambiar el estado solo si el Switch no está bloqueado
    if (!isDisabled) {
      setIsChecked((prevIsChecked) => !prevIsChecked); // Invertir el valor actual
      if (!isChecked) {
        onSwitchActivate(); // Llamar a la función del controlador al activar el Switch
      }
    }
  };

  const PinkSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-track": {
      opacity: "1 !important", // Sobrescribe la opacidad
    },
    "& .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track": {
      opacity: "1 !important", // Sobrescribe la opacidad para el estado deshabilitado
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: `${green[600]} !important`,
      "&:hover": {
        backgroundColor: `opacity: unset !important;`,
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: `${green[600]} !important`,
    },
  }));

  return (
    <>
      <Card sx={style}>
        <MDBox
          mt={-7}
          color="white"
          bgColor="info"
          variant="gradient"
          borderRadius="lg"
          p={2}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Typography id="modal-modal-title" component="h2" style={{ margin: "auto" }}>
            Detalles del usuario:
            <br />
            {data?.card_user?.name} {data?.card_user?.lastname}
          </Typography>
        </MDBox>
        <Divider variant="middle" />
        <div>
          {shouldShowSwitch && (
            <>
              <Typography component="div">{isChecked ? "Ingresado" : "No ha ingresado"}</Typography>
              <PinkSwitch checked={isChecked} onChange={handleSwitchChange} disabled={isDisabled} />
            </>
          )}
        </div>
        <div style={{ maxHeight: "80%", overflowY: "auto" }}>
          <MDBox
            sx={content}
            style={{
              justifyContent: "space-around",
            }}
          >
            <Box sx={{ marginRight: "20px" }}>
              <Typography color={"black"} fontWeight={500}>
                Documento de identidad
              </Typography>
              <MDInput
                disabled={true}
                style={{ marginTop: "5px" }}
                label=""
                value={data?.card_user?.number_id}
              />
            </Box>
            <Box>
              <Box>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Vigencias
                </Typography>
              </Box>
            </Box>
            {/* <Box display="flex" alignItems="flex-start"> */}
            <Box sx={{ marginRight: "25px" }}>
              <Typography color={"black"} fontWeight={500}>
                Desde...
              </Typography>
              <MDInput
                disabled={true}
                type="datetime-local"
                style={{ marginTop: "5px" }}
                label=""
                value={data?.created}
              />
            </Box>
            <Box sx={{ marginRight: "25px" }}>
              <Typography color={"black"} fontWeight={500}>
                Hasta...
              </Typography>
              <MDInput
                type="datetime-local"
                disabled={true}
                style={{ marginTop: "5px" }}
                label=""
                value={data?.expires_in}
              />
            </Box>
            {/* </Box> */}
            <Box display="flex" alignItems="flex-start">
              <Box sx={{ marginRight: "25px" }}>
                <Typography color={"black"} fontWeight={500}>
                  Estado
                </Typography>
                <MDInput
                  disabled={true}
                  style={{ marginTop: "5px" }}
                  label=""
                  value={data?.status}
                />
              </Box>
              <Box sx={{ marginRight: "25px" }}>
                <Typography color={"black"} fontWeight={500}>
                  Tipo de carnet
                </Typography>
                <MDInput
                  disabled={true}
                  style={{ marginTop: "5px" }}
                  label=""
                  value={data?.card_type?.name}
                />
              </Box>
            </Box>
            {data?.data_source_file && (
              <Box sx={{ marginRight: "25px" }}>
                <Typography color={"black"} fontWeight={500}>
                  Evento
                </Typography>
                <MDInput
                  disabled={true}
                  style={{ marginTop: "5px" }}
                  label=""
                  multiline
                  value={data?.data_source_file?.data_source?.source_description}
                />
                {/* <Typography variant="body1">
                  {data?.data_source_file?.data_source?.source_description}
                </Typography> */}
              </Box>
            )}
          </MDBox>
        </div>
        <Divider variant="middle" />
      </Card>
    </>
  );
}

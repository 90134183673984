// fetchWrapper.js
const fetchWrapper = async (url, body = {}, method = "GET") => {
  const token = localStorage.getItem("token"); // O desde donde guardes tu token
  const user = localStorage.getItem("user_id"); // O desde donde guardes tu user

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    user: user,
  };

  const config = {
    method: method,
    headers,
  };

  if (method !== "GET") {
    config.body = body;
  }

  const response = await fetch(url, config);

  // Manejo de errores si es necesario
  if (!response) {
    // Manejar errores
  }

  return response;
};

export default fetchWrapper;

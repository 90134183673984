/* 
██╗██╗░░░██╗  ██████╗░██╗░██████╗░██╗████████╗░█████╗░██╗░░░░░
██║██║░░░██║  ██╔══██╗██║██╔════╝░██║╚══██╔══╝██╔══██╗██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░██╗░██║░░░██║░░░███████║██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░╚██╗██║░░░██║░░░██╔══██║██║░░░░░
██║╚██████╔╝  ██████╔╝██║╚██████╔╝██║░░░██║░░░██║░░██║███████╗
╚═╝░╚═════╝░  ╚═════╝░╚═╝░╚═════╝░╚═╝░░░╚═╝░░░╚═╝░░╚═╝╚══════╝
╔════════════════════════════════════════════════════════════╗
║              EQUIPO DE DESARROLLO DE SOFTWARE              ║
║                   MODULO DESARROLLADO POR                  ║
╟┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄╢
║ Victor Manuel Calle Londoño                                ║
║ victor.calle@est.iudigital.edu.co                          ║
╚════════════════════════════════════════════════════════════╝
*/
import {
  Box,
  Card,
  Divider,
  Icon,
  Modal,
  Pagination,
  Stack,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import verDetallesImg from "assets/images/ver_detalles.svg";
import PropTypes from "prop-types";
import data from "layouts/tables/data/authorsTableData";
import MDBadge from "components/MDBadge";
import DownloadIcon from "@mui/icons-material/Download";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

export default function ModalAddCarnet({
  open,
  dataCard,
  name,
  error,
  pageIndexAndPageSize,
  entriesStart,
  entriesEnd,
  totalData,
  totalPages,
  currentPage,
  handlePageChange,
  handleClose,
  handleOpenModalAdd,
  handleSubmit,
  getFieldValue,
  carnetTypes,
  handleFieldChange,
  isDisabled,
  statusTypes,
  isFieldValid,
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: "800px",
    maxHeight: "600px",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
  };

  const content = {
    margin: "0",
    padding: "0",
    display: "grid",
    width: "100%",
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    marginBottom: "15px",
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={style}>
          <MDBox
            mt={-7}
            color="white"
            bgColor="info"
            variant="gradient"
            borderRadius="lg"
            p={2}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Typography id="modal-modal-title" component="h2">
              Asignar carnet a usuario:{` ${name}`}
            </Typography>
            <Icon
              sx={{
                fontSize: "2em",
                stroke: "currentColor",
                strokeWidth: "2px",
                cursor: "pointer",
                marginLeft: "auto",
              }}
              onClick={handleClose}
            >
              close
            </Icon>
          </MDBox>
          <Divider variant="middle" />
          <form onSubmit={handleSubmit} style={content}>
            <Box>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Tipo de carnet
              </Typography>
              <FormControl sx={{ minWidth: 80 }} error={!isFieldValid.card_type_id}>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={getFieldValue("card_type_id")}
                  onChange={(e) => handleFieldChange("card_type_id", e.target.value)}
                  style={{ fontSize: "15px", padding: "10px" }}
                  // disabled={isDisabled}
                  displayEmpty
                >
                  <MenuItem value={""}>Seleccione...</MenuItem>
                  {carnetTypes
                    ? carnetTypes.map((carnet) => {
                        return (
                          <MenuItem key={carnet.id} value={carnet.id}>
                            {carnet.name}
                          </MenuItem>
                        );
                      })
                    : []}
                </Select>
                {!isFieldValid.card_type_id && (
                  <FormHelperText>Tipo de carnet requerido</FormHelperText>
                )}
              </FormControl>
            </Box>
            <Box>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Fecha de habilitación
              </Typography>
              <MDInput
                error={!isFieldValid.created}
                helperText={!isFieldValid.created ? "Ingrese una fecha." : ""}
                FormHelperTextProps={{ className: !isFieldValid.created ? "Mui-error" : "" }}
                type="date"
                style={{ marginTop: "5px" }}
                label=""
                value={getFieldValue("created")}
                onChange={(e) => handleFieldChange("created", e.target.value)}
              />
            </Box>
            <Box>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Fecha de expiración
              </Typography>
              <MDInput
                error={!isFieldValid.expires_in}
                helperText={!isFieldValid.expires_in ? "Ingrese una fecha." : ""}
                type="date"
                style={{ marginTop: "5px" }}
                label=""
                value={getFieldValue("expires_in")}
                onChange={(e) => handleFieldChange("expires_in", e.target.value)}
                FormHelperTextProps={{ className: !isFieldValid.expires_in ? "Mui-error" : "" }}
              />
            </Box>
            <Box>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Estado
              </Typography>
              <FormControl sx={{ minWidth: 80 }} error={!isFieldValid.status}>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={getFieldValue("status")}
                  onChange={(e) => handleFieldChange("status", e.target.value)}
                  style={{ fontSize: "15px", padding: "10px" }}
                  // disabled={isDisabled}
                  displayEmpty
                >
                  <MenuItem value={""}>Seleccione...</MenuItem>
                  {statusTypes
                    ? statusTypes.map((status) => {
                        return (
                          <MenuItem key={status.state} value={status.state}>
                            {status.state}
                          </MenuItem>
                        );
                      })
                    : []}
                </Select>
                {!isFieldValid.status && <FormHelperText>Estado requerido</FormHelperText>}
              </FormControl>
            </Box>
          </form>
          <MDBox
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
            m={-0.5}
            mb={-2}
          >
            <MDButton onClick={handleSubmit} type="submit" disabled={isDisabled} color="success">
              Guardar
            </MDButton>
          </MDBox>
          <MDBox
            style={{
              display: "right",
              justifyContent: "flex-end",
              marginRight: "2vw",
              marginBottom: "1rem",
            }}
          ></MDBox>
        </Card>
      </Modal>
    </>
  );
}

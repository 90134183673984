import React from "react";
import fetchWrapper from "../../../fetchWrapper";

//import { useAuth } from "../../../context/index";
//const { token, login, logout } = useAuth();
const getCardPerId = async (id) => {
  try {
    const response = await fetchWrapper(`${process.env.REACT_APP_API_URL}/cards/verify_card/${id}`);
    // const { users } = await response.json();
    const result = await response.json();
    console.log(result);
    const { success } = result;
    if (success === true) {
      const { payload } = result;
      return { payload };
    } else if (success === false) {
      const message = "Error del servidor al realizar la solicitud";
      const status = false;
      return { status, message };
      //throw new Error(`Error del servidor al realizar la solicitud`);
    }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};

const updatedJoinCarnet = async (id, statusCarnet) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/cards/edit/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(statusCarnet),
    });
    const result = await response.json();
    const { success, code } = result;
    if (success === true) {
      return result;
    } else if (success === false) {
      const message = "Error del servidor al realizar la solicitud";
      const status = false;
      return { status, message };
    }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};

export { getCardPerId, updatedJoinCarnet };

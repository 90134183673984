import MDInput from "components/MDInput";
import React from "react";
import { FormControl, InputLabel, MenuItem, Select, Alert, FormHelperText } from "@mui/material";
import MDButton from "components/MDButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";

import BackspaceIcon from "@mui/icons-material/Backspace";
import ClearIcon from "@mui/icons-material/Clear";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

export default function Filter({
  dataFilter,
  filter,
  handleFilter,
  isVisible,
  handleChange,
  search,
  filterType,
  handleFilterType,
  isVisibleSelectDateInput,
  isVisibleType,
  dateFrom,
  dateUntil,
  handleChangeDateFrom,
  handleChangeDateUntil,
  handleClickSubmit,
  dataTypeCard,
  dataStatus,
  dataSourceTypes,
  handleAddFilter,
  filters,
  showErrorAlert,
  handleDeleteFilter,
  isFieldValid,
}) {
  return (
    <>
      {/* {showErrorAlert && <Alert severity="error">El filtro ya está en la lista o es vacío</Alert>} */}
      {filters.length !== 0 && (
        <div>
          {filters.map((item) => (
            <MDButton
              endIcon={<BackspaceIcon />}
              variant="contained"
              color="error"
              size="medium"
              key={item.id}
              sx={{ m: 1 }} // Establecer el color del texto a blanco
              onClick={() => handleDeleteFilter(item.id)}
            >
              {item.label}
            </MDButton>
          ))}
        </div>
      )}
      <div style={{ marginTop: "10px", display: "flex", alignItems: "center", flexWrap: "wrap" }}>
        <FormControl style={{ left: "20px" }} variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="demo-simple-select-label">Filtrado por...</InputLabel>
          <Select
            style={{ width: "auto" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filter}
            label="Filtrado por..."
            onChange={(event) => {
              handleFilter(event); // Llamar a handleFilter con el valor seleccionado
            }}
          >
            <MenuItem value="">NINGUNO</MenuItem>
            {dataFilter &&
              dataFilter[0].map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  {item.field}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        {isVisible && (
          <>
            <MDInput
              error={!isFieldValid.search}
              helperText={!isFieldValid.search ? "Ingrese un valor." : ""}
              FormHelperTextProps={{
                className: !isFieldValid.search ? "Mui-error" : "",
              }}
              style={{
                left: "20px",
                marginRight: "5px",
              }}
              required
              label="Buscar"
              onChange={handleChange}
              value={search}
            />
            <MDButton
              style={{ left: "20px" }}
              variant="gradient"
              size="small"
              color="info"
              onClick={handleAddFilter}
            >
              Agregar Filtro
            </MDButton>
          </>
        )}
        <>
          {isVisibleSelectDateInput && (
            <>
              <MDInput
                type="date"
                style={{ marginLeft: "20px" }}
                label="Desde"
                value={dateFrom}
                onChange={handleChangeDateFrom}
                error={!isFieldValid.date}
                helperText={!isFieldValid.date ? "Ingresa una fecha en ambos campos." : ""}
                FormHelperTextProps={{
                  className: !isFieldValid.date ? "Mui-error" : "",
                }}
              />
              <MDInput
                type="date"
                style={{ marginLeft: "25px" }}
                label="Hasta"
                value={dateUntil}
                onChange={handleChangeDateUntil}
                error={!isFieldValid.date}
                helperText={!isFieldValid.date ? "Ingresa una fecha en ambos campos." : ""}
                FormHelperTextProps={{
                  className: !isFieldValid.date ? "Mui-error" : "",
                }}
              />
              <MDButton
                style={{ left: "20px" }}
                variant="gradient"
                color="info"
                size="small"
                onClick={handleAddFilter}
              >
                Agregar Filtro
              </MDButton>
            </>
          )}
        </>
        {isVisibleType && (
          <>
            {filter === "type_carnet" && (
              <>
                <FormControl
                  style={{ left: "20px" }}
                  variant="standard"
                  sx={{ m: 1, minWidth: 120 }}
                  error={!isFieldValid.search}
                >
                  <InputLabel id="demo-simple-select-label2">Selecciona..</InputLabel>
                  <Select
                    labelId="demo-simple-select-label2"
                    id="demo-simple-select221"
                    value={search}
                    onChange={handleFilterType}
                  >
                    <MenuItem value={""}>Ninguno</MenuItem>
                    {dataTypeCard
                      ? dataTypeCard.map(({ id, name } = typeCard) => {
                          return (
                            <MenuItem key={id} value={name}>
                              {name}
                            </MenuItem>
                          );
                        })
                      : []}
                  </Select>
                  {!isFieldValid.search && (
                    <FormHelperText>Tipo de carnet requerido</FormHelperText>
                  )}
                </FormControl>
              </>
            )}
            {filter === "status" && (
              <>
                <FormControl
                  style={{ left: "20px" }}
                  variant="standard"
                  sx={{ m: 1, minWidth: 120 }}
                  error={!isFieldValid.search}
                >
                  <InputLabel id="demo-simple-select-label2">Selecciona..</InputLabel>
                  <Select
                    labelId="demo-simple-select-label2"
                    id="demo-simple-select222"
                    value={search}
                    onChange={handleFilterType}
                  >
                    <MenuItem value={""}>Ninguno</MenuItem>
                    {dataStatus
                      ? dataStatus.map(({ id, state } = typeCard) => {
                          return (
                            <MenuItem key={id} value={state}>
                              {state}
                            </MenuItem>
                          );
                        })
                      : []}
                  </Select>
                  {!isFieldValid.search && <FormHelperText>Estado requerido</FormHelperText>}
                </FormControl>
              </>
            )}
            {filter === "source_type_name" && (
              <>
                <FormControl
                  style={{ left: "20px" }}
                  variant="standard"
                  sx={{ m: 1, minWidth: 120 }}
                  error={!isFieldValid.search}
                >
                  <InputLabel id="demo-simple-select-label2">Selecciona..</InputLabel>
                  <Select
                    labelId="demo-simple-select-label2"
                    id="demo-simple-select223"
                    value={search}
                    onChange={handleFilterType}
                  >
                    <MenuItem value={""}>Ninguno</MenuItem>
                    {dataSourceTypes
                      ? dataSourceTypes.map(({ id, name } = typeCard) => {
                          return (
                            <MenuItem key={id} value={name}>
                              {name}
                            </MenuItem>
                          );
                        })
                      : []}
                  </Select>
                  {!isFieldValid.search && <FormHelperText>Estado requerido</FormHelperText>}
                </FormControl>
              </>
            )}
            <MDButton
              style={{ left: "20px" }}
              variant="gradient"
              size="small"
              color="info"
              onClick={handleAddFilter}
            >
              Agregar Filtro
            </MDButton>
          </>
        )}
      </div>
      <MDButton
        style={{ left: "20px", marginTop: "10px" }}
        variant="gradient"
        color="info"
        onClick={handleClickSubmit}
      >
        Buscar
      </MDButton>
    </>
  );
}
